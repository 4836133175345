<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-flash-alert-outline"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">ProgGeoPerdas ANEEL</div>
      </template>
      <v-btn
        v-if="accessReleased('PROG_GEOPERDAS_ANEEL')"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('add')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Executa MT-BT
      </v-btn>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <general-progress-bars
        class="mt-11"
        :items="downloadProgressData"
        prefixText="Baixando arquivo ..."
      />
      <v-divider class="mt-10" />
      <v-data-table
        :headers="[
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'Lote oficial?',
            value: 'lote_oficial_txt'
          },
          {
            text: 'Título',
            value: 'nome'
          },
          {
            text: 'Servidor',
            value: 'servidor'
          },
          {
            text: 'Mês dos dados',
            value: 'data_registro'
          },
          {
            text: 'CTMTS',
            value: 'ctmts_selecionados'
          },
          {
            text: 'Falhas',
            value: 'numero_ctmts_com_status_falha'
          },
          {
            text: 'Sucessos',
            value: 'numero_ctmts_com_status_sucesso'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            sortable: false,
            text: 'Ações',
            value: 'actions',
            class: 'pl-4'
          }
        ]"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum cálculo do ProgGeoPerdas encontrado"
      >
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }} v{{ item.versao }}
        </template>
        <template v-slot:[`item.servidor`]="{ item }">
          <div :title="`Host: ${item.host} Port: ${item.port}`">
            {{ item.servidor }}
          </div>
          <div>{{ item.database }}</div>
        </template>
        <template v-slot:[`item.lote_oficial_txt`]="{ item }">
          <lote-oficial :loteOficial="item.lote_oficial" />
        </template>
        <template v-slot:[`item.ctmts_selecionados`]="{ item }">
          <div
            class="clickable-item"
            @click="openDialogCtmtsSelecionados(item.ctmts_selecionados)"
          >
            {{ item.ctmts_selecionados | exibeResumoCtmtsSelecionados }}
          </div>
        </template>
        <template v-slot:[`item.numero_ctmts_com_status_falha`]="{ item }">
          <div :style="{ color: 'red' }">
            {{ item.numero_ctmts_com_status_falha }}
            <span
              v-if="
                calculaPorcentagem(
                  item.numero_ctmts_com_status_falha,
                  item.numero_ctmts_selecionados
                ) > 0
              "
            >
              ({{
                calculaPorcentagem(
                  item.numero_ctmts_com_status_falha,
                  item.numero_ctmts_selecionados
                ) | parseNumberToFloatBR
              }}%)
            </span>
          </div>
        </template>
        <template v-slot:[`item.numero_ctmts_com_status_sucesso`]="{ item }">
          <div :style="{ color: 'green' }">
            {{ item.numero_ctmts_com_status_sucesso }}
            <span
              v-if="
                calculaPorcentagem(
                  item.numero_ctmts_com_status_sucesso,
                  item.numero_ctmts_selecionados
                ) > 0
              "
            >
              ({{
                calculaPorcentagem(
                  item.numero_ctmts_com_status_sucesso,
                  item.numero_ctmts_selecionados
                ) | parseNumberToFloatBR
              }}%)
            </span>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="
              !['SUCESSO', 'FALHA'].includes(item.status) ? 'cursor-block' : ''
            "
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="() => baixarArquivoResultadoCalculoPerdas(item)"
            :loading="
              downloadProgressData[item.id] !== undefined ? true : false
            "
            :disabled="!['SUCESSO', 'FALHA'].includes(item.status)"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-btn
            v-if="accessReleased('PROG_GEOPERDAS_ANEEL_LOGS')"
            min-width="0"
            class="px-1"
            fab
            icon
            x-small
            @click="$router.push(`logs/${item.id}`)"
          >
            <v-icon small> mdi-file-tree </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item)"
              >
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Deletar </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="userIsAdmin"
                @click="atualizaLoteOficial(item)"
              >
                <v-list-item-title>
                  <v-icon
                    small
                    v-if="item.lote_oficial"
                  >
                    mdi-star-off
                  </v-icon>
                  <v-icon
                    small
                    v-else
                  >
                    mdi-star
                  </v-icon>
                  <span v-if="item.lote_oficial"> Definir como Comum </span>
                  <span v-else> Definir como Oficial </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogCreationLog(item)">
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
              >
                mdi-refresh
              </v-icon>
            </template>
            <span> Clique aqui para recarregar as execuções </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <v-dialog
      v-model="dialogCtmtsSelecionados"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>CTMTS selecionados</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          <v-textarea
            class="ta-padding-c"
            rows="10"
            no-resize
            background-color="#EEEEEE"
            :readonly="true"
            :value="dialogCtmtsSelecionadosData.join('\n')"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="green darken-1"
            @click="dialogCtmtsSelecionados = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">
        Deletar Lote #{{ dialogDeleteData.id }}
      </template>
      <template slot="body">
        Tem certeza que deseja deletar os cálculos do lote
        <strong> #{{ dialogDeleteData.id }} </strong>?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import AuthService from '@/services/AuthService';
import ProgGeoPerdasAneelService from '@/services/ProgGeoPerdasAneelService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    LoteOficial: () => import('./LoteOficial.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue')
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    userIsAdmin: false,
    search: '',
    dialogCtmtsSelecionados: false,
    dialogCtmtsSelecionadosData: [],
    dialogDelete: false,
    dialogDeleteData: { id: null },
    downloadProgressData: {}
  }),
  mounted() {
    this.getUserIsAdmin();
  },
  methods: {
    getUserIsAdmin() {
      AuthService.userIsAdmin().then(
        (userIsAdmin) => (this.userIsAdmin = userIsAdmin)
      );
    },
    atualizaLoteOficial(item) {
      const { id, lote_oficial } = item;
      ProgGeoPerdasAneelService.atualizaLoteOficial(id, lote_oficial ? 0 : 1)
        .then(() => {
          this.$toast.success('Lote oficial atualizado com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao tentar atualizar o lote oficial.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
    },
    calculaPorcentagem(num1, num2) {
      if (num2 <= 0) return 0;
      return ((num1 / num2) * 100).toFixed(2);
    },
    canDeleteItem(item) {
      return item.status === 'AGUARDANDO';
    },
    openDialogCtmtsSelecionados(ctmtsSelecionados) {
      this.dialogCtmtsSelecionadosData = ctmtsSelecionados;
      this.dialogCtmtsSelecionados = true;
    },
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.usuario,
        created: item.created
      };
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id
      };
    },
    deletarItem(id) {
      ProgGeoPerdasAneelService.deletar(id)
        .then(() => {
          this.$toast.success(
            'Execução de cálculos de perdas removida com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao deletar a execução de cálculos de perdas.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error(error);
        });
      this.dialogDelete = false;
    },
    baixarArquivoResultadoCalculoPerdas(calculo) {
      const { id, data_registro, versao } = calculo;

      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      let nomeArquivo = `calculo-perdas-tecnicas-${id}-bdgd-mes-`;
      nomeArquivo += `${data_registro}-versao-${versao}-${timestamp}.zip`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      ProgGeoPerdasAneelService.baixarArquivoResultadoCalculoPerdas(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com o resultado do cálculo de perdas técnicas.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  },
  filters: {
    exibeResumoCtmtsSelecionados(arrayCtmts) {
      let formattedValue = arrayCtmts[0].toUpperCase();
      let lengthArray = arrayCtmts.length;
      formattedValue =
        lengthArray > 1
          ? `${formattedValue} + ${lengthArray - 1}`
          : formattedValue;
      return formattedValue;
    }
  }
};
</script>
