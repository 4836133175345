<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <prog-geo-perdas-aneel-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getAll"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgGeoPerdasAneelService from '@/services/ProgGeoPerdasAneelService';
import ProgGeoPerdasAneelDataTable from '@/components/perdas-tecnicas/prog-geo-perdas-aneel/ProgGeoPerdasAneelDataTable';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],
  components: {
    ProgGeoPerdasAneelDataTable
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getAll);
  },
  methods: {
    getAll() {
      this.loading = true;
      ProgGeoPerdasAneelService.getAll()
        .then(({ data }) => (this.items = data.data))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
